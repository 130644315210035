<script lang="ts">
    import { onMount, onDestroy } from 'svelte';
    import { t } from 'svelte-i18n';
    import {
        currentContent,
        currentPage,
        interviewData,
        isAudioPlaying,
        isLoading,
        isUserTalking
    } from "../../redux/stores";
    import PreInterviewContent from "../PreInterviewContent.svelte";
    import {AudioStreamer} from "./AudioStreamer";
    import ApplicantLeftMeeting from "./ApplicantLeftMeeting.svelte";
    import {startRecordingWebcam, stopRecordingWebcam} from "../../service/WebcamRecorderService";
    import {startVideoStream, stopVideoStream} from "../../service/CameraInput";
    let audioStreamer = new AudioStreamer("START:"+$interviewData.id);

    onMount(async () => {
        $isLoading = true
        await audioStreamer.init();
        startVideoStream();
        await startRecordingWebcam();
    });

    onDestroy(() => {
        audioStreamer.destroy();
        stopRecordingWebcam();
        stopVideoStream();
    });

    isAudioPlaying.subscribe(isPlaying => {if (isPlaying){$isLoading = false}});

    function stopCall() {
        $currentPage = PreInterviewContent;
        $currentContent = ApplicantLeftMeeting;
        stopRecordingWebcam();
        stopVideoStream();
    }

    let showAlert = true;
</script>

{#if $isLoading}
    <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-800 z-50">
        <span class="loading loading-spinner loading-lg custom-spinner-size"></span>
    </div>
{/if}
<div class="flex flex-col items-center justify-center min-h-screen" style="background-color: #272829;">
    {#if showAlert}
        <div role="alert" class="alert alert-error">
            <svg on:click={() => {showAlert = false}} xmlns="http://www.w3.org/2000/svg" class="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{$t("slow_internet_warning")}</span>
        </div>
    {/if}

    <!-- Container -->
    <div class="flex flex-col items-center w-4/5 md:flex-row md:justify-center">
        <!-- Image Container 1 -->
        <div class="flex flex-col items-center p-6" id="imageContainer1">
            <img class="custom-mask-circle {$isAudioPlaying ? 'speaking' : ''}" id="image1" src="/recruiter.png" alt="Image 1">
            <div class="text-xl text-white font-semibold mt-4">{$t("interviewer_name")}</div>
        </div>

        <!-- Image Container 2 -->
        <div class="flex flex-col items-center p-6" id="videoContainer">
            <video id="webcam" class="custom-mask-circle {$isUserTalking ? 'speaking' : ''}" autoplay playsinline></video>
            <div class="text-xl text-white font-semibold mt-4">{$interviewData.applicant.name}</div>
        </div>
    </div>

    <!-- Call Controls -->
    <div class="fixed bottom-5 left-1/2 transform -translate-x-1/2 flex space-x-4">
        <!-- Mute Button -->
        <button id="muteBtn" class="flex items-center justify-center p-4 bg-yellow-400 text-gray-800 rounded-lg shadow-md">
            <i class="fas fa-microphone-slash text-xl"></i>
            {$t("mute")}
        </button>
        <!-- Stop Call Button -->
        <button id="stopCallBtn" class="flex items-center justify-center p-4 bg-red-500 text-white rounded-lg shadow-md" on:click={()=> stopCall()}>
            <i class="fas fa-phone-slash text-xl"></i>
            {$t("stop_call")}
        </button>
    </div>
</div>

<style>
    /* CSS for the pulsing animation */
    @keyframes pulse {
        0% {
            box-shadow: 0 0 5px #FFF;
        }
        50% {
            box-shadow: 0 0 20px #FFF, 0 0 30px #FFF;
        }
        100% {
            box-shadow: 0 0 5px #FFF;
        }
    }
    :global(.speaking) {
        animation: pulse 1s infinite;
    }

    .speaking {
        animation: pulse 1s infinite;
    }

    .custom-mask-circle {
        border-radius: 50%;
        overflow: hidden;
        width: 600px;
        height: 600px;
        object-fit: cover;
    }

    .custom-spinner-size {
        width: 250px;
        height: 250px;
        border-width: 8px;
    }
</style>
