import type {WebSocketMessage} from "../model/WebSocketMessage";
import {get} from 'svelte/store';
import {currentContent, currentPage, interviewData} from "../redux/stores";
import type {InterviewWS} from "../model/InterviewWS";
import PreInterviewContent from "../lib/PreInterviewContent.svelte";
import ApplicantLeftMeeting from "../lib/interview/ApplicantLeftMeeting.svelte";
import WebsocketClosed from "../lib/interview/WebsocketClosed.svelte";
import FinishInterviewComponent from "../lib/interview/FinishInterviewComponent.svelte";


const mediaConstraints: MediaStreamConstraints = {
    video: true,
    audio: true,
};

let ws: WebSocket
let mediaStream: MediaStream
let mediaRecorder: MediaRecorder


// Function to initialize media capture and WebSocket streaming
export async function startRecordingWebcam() {
    try {
        ws = new WebSocket(import.meta.env.VITE_STREAM_VIDEO_RECORDING)
        // Request access to user's webcam and microphone
        mediaStream = await navigator.mediaDevices.getUserMedia(mediaConstraints);

        // Create a MediaRecorder instance to capture the media stream
        mediaRecorder = new MediaRecorder(mediaStream);

        // Setup WebSocket connection and send chunks every second
        mediaRecorder.ondataavailable = (event: BlobEvent) => {
            if (event.data.size > 0) {
                console.log("Sending chunk of data to WebSocket");

                // Send the recorded media blob to the WebSocket server
                ws.send(event.data);
            }
        };

        // Handle WebSocket open and error events
        ws.onopen = () => {
            ws.send(JSON.stringify(getOpenMessage()))
            mediaRecorder.start(1000); // Record in chunks of 1 second (1000 ms)
        };

        ws.onerror = (error) => {
          throw error
        };

        ws.onclose = (event) => {
            stopMedia()
            if (event.wasClean) {
                console.log("Connection closed cleanly");
            } else {
                console.log("Connection closed abruptly");
                throw event
            }
            if (event.code === 1000) {
                console.log("Client closed the connection");
                currentPage.set(PreInterviewContent)
                currentContent.set(FinishInterviewComponent)
            } else if (event.code === 1006) {
                currentPage.set(PreInterviewContent)
                currentContent.set(WebsocketClosed)
            } else {
                currentPage.set(PreInterviewContent)
                currentContent.set(WebsocketClosed)
            }
        };
    } catch (error) {
        console.error("Error accessing media devices.", error);
    }
}

export function stopRecordingWebcam() {
    stopMedia()
    if (ws.readyState === WebSocket.OPEN) {
        const closeMsg: WebSocketMessage = {
            id: get(interviewData).id, type: "STOP_RECORDING"
        }
        ws.send(JSON.stringify(closeMsg))
        ws.close();
        console.log("WebSocket connection closed.");
    }
}

function stopMedia() {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
    }
    if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
            track.stop();
        });
    }
}

function getOpenMessage(): WebSocketMessage {
    const applicant = get(interviewData).applicant.name + " " + get(interviewData).applicant.surname
    const interviewWS: InterviewWS = {
        applicant: applicant,
        code: get(interviewData).code,
        id: get(interviewData).id,
        recruiter: "Jane"
    }
    return {
        id: get(interviewData).id, type: "init", interview: interviewWS
    }
}