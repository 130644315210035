<script lang="ts">
    import { t } from 'svelte-i18n';
    import {currentPage, interviewData} from "../redux/stores.js";
    import RequestCameraAndMic from "../RequestCameraAndMic.svelte";
    import SendAudioTest from "./interview/SendAudioTest.svelte";

    async function getDevices() {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true });

            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoInputSelect = document.getElementById('videoInput');
            const audioInputSelect = document.getElementById('audioInput');

            devices.forEach(device => {
                const option = document.createElement('option');
                option.value = device.deviceId;
                option.textContent = device.label || `Device ${device.deviceId}`;
                if (device.kind === 'videoinput') {
                    videoInputSelect.appendChild(option);
                } else if (device.kind === 'audioinput') {
                    audioInputSelect.appendChild(option);
                }
            });
        } catch (err) {
            console.error('Error getting media devices.', err);
        }
    }

    async function updateStream() {
        try {
            const videoInput = document.getElementById('videoInput').value;
            const audioInput = document.getElementById('audioInput').value;
            const constraints = {
                video: { deviceId: videoInput ? { exact: videoInput } : undefined },
                audio: { deviceId: audioInput ? { exact: audioInput } : undefined }
            };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            const videoElement = document.getElementById('preview');
            const oldStream = videoElement.srcObject;
            videoElement.srcObject = stream;
            if (oldStream) {
                oldStream.getTracks().forEach(track => track.stop());
            }
        } catch (err) {
            console.error('Error updating media devices.', err);
        }
    }

    async function startVideo() {
        try {
            const videoInput = document.getElementById('videoInput').value;
            const audioInput = document.getElementById('audioInput').value;
            const constraints = {
                video: { deviceId: videoInput ? { exact: videoInput } : undefined },
                audio: { deviceId: audioInput ? { exact: audioInput } : undefined }
            };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            document.getElementById('preview').srcObject = stream;
        } catch (err) {
            console.error('Error accessing media devices.', err);
        }
    }

    function stopVideo() {
        const videoElement = document.getElementById('preview');
        const stream = videoElement.srcObject;

        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            videoElement.srcObject = null;
        }
    }

    getDevices().then(startVideo);

    const joinInterview = () => {
        stopVideo();
        $currentPage = SendAudioTest;
    };
</script>

<RequestCameraAndMic/>
<section class="text-gray-600 body-font mt-20 p-10">
    <div class="container mx-auto flex flex-col md:flex-row items-center">
        <div class="md:w-1/2 md:pr-16 flex flex-col md:items-start text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="title-font text-4xl mb-8 font-medium text-gray-900">
                {$t("ready_to_join_meeting", { name: interviewData.applicant.name })}
            </h1>
            <p class="mb-8 leading-relaxed text-lg">
                {$t("ensure_camera_mic")}
            </p>
            <button type="button" class="bg-blue-600 text-white py-3 px-6 rounded-lg w-full transition duration-300 ease-in-out hover:bg-blue-700" on:click={()=> joinInterview()}>
                {$t("join_now")}
            </button>
        </div>
        <div class="md:w-1/2 w-full">
            <div class="border border-gray-300 rounded-lg p-6 mb-4" style="height: 0; padding-bottom: 56.25%; position: relative;">
                <video id="preview" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; transform: scaleX(-1);" autoplay></video>
            </div>
            <select id="videoInput" class="mb-4 border border-gray-300 py-2 px-4 rounded-lg w-full"></select>
            <select id="audioInput" class="mb-4 border border-gray-300 py-2 px-4 rounded-lg w-full"></select>
        </div>
    </div>
</section>
