
// audioStreamer.ts
import { Playback } from "./Playback";
import {currentContent, currentPage, isAudioPlaying, isUserTalking} from "../../redux/stores";
import ApplicantLeftMeeting from "./ApplicantLeftMeeting.svelte";
import PreInterviewContent from "../PreInterviewContent.svelte";
import type {InterviewEvent} from "../../model/communication/InterviewEvent";
import WebsocketClosed from "./WebsocketClosed.svelte";
import {stopVideoStream} from "../../service/CameraInput";
import {stopRecordingWebcam} from "../../service/WebcamRecorderService";
import FinishInterviewComponent from "./FinishInterviewComponent.svelte";

export class AudioStreamer {
    private audioContext: AudioContext;
    private mediaStream: MediaStream;
    private audioProcessor: ScriptProcessorNode;
    private webSocket: WebSocket;
    private isTalking = false;
    private recordedTime = 0; // Time of audio recorded in milliseconds

    private silenceThreshold = 0.02;
    private audioBuffer: Float32Array[] = []; // Buffer to accumulate audio data
    private silenceDuration = 0; // Duration of continuous silence in milliseconds

    private silenceTimeout = 1700; // 1.3 seconds timeout
    private playback: Playback;
    private readonly openMessage: InterviewEvent
    private isRecruiterTalking: boolean = false

    private didRecruiterSpeak = false
    private isWaitingForResponse = false

    constructor(interviewEvent: InterviewEvent) {
        this.playback = new Playback();
        this.openMessage = interviewEvent

    }

    async init() {
        isAudioPlaying.subscribe(playing => {
            if (!playing){
                this.startStreaming()
                this.isWaitingForResponse = false;
            }
            if (playing) {this.didRecruiterSpeak = true}
            this.isRecruiterTalking = playing
        })


        try {
            this.webSocket = new WebSocket(import.meta.env.VITE_STREAM_AUDIO_TO_INTERVIEWER);

            await this.startStreaming()


            this.webSocket.onopen = () => {
                this.webSocket.send(JSON.stringify(this.openMessage));
            };

            this.webSocket.onmessage = (event) => {
                if (event.data=='END-MESSAGE'){
                    this.playback.process()
                    this.playback = new Playback()
                }else{
                    this.playback.playBase64Audio(event.data);
                }

            };
            this.webSocket.onclose = (event) => {
                this.playback.stopAudio()
                stopVideoStream()
                stopRecordingWebcam()
                if (event.wasClean) {
                    console.log("Connection closed cleanly");
                } else {
                    console.log("Connection closed abruptly");
                    throw event
                }
                if (event.code === 1000) {
                    console.log("Client closed the connection");
                    currentPage.set(PreInterviewContent)
                    currentContent.set(FinishInterviewComponent)
                } else if (event.code === 1006) {
                    currentPage.set(PreInterviewContent)
                    currentContent.set(WebsocketClosed)
                } else {
                    currentPage.set(PreInterviewContent)
                    currentContent.set(WebsocketClosed)
                    console.log("Server might have closed the connection with reason:", event.reason);
                }
              this.destroy()
            };

            this.audioProcessor.onaudioprocess = (event) => {
                if (this.didRecruiterSpeak && !this.isWaitingForResponse) this.processAudio(event)
            };

        } catch (error) {
            currentPage.set(PreInterviewContent)
            currentContent.set(WebsocketClosed)
            throw error
        }
    }



    private processAudio(event: AudioProcessingEvent) {
        if (this.isRecruiterTalking) return

        const inputData = event.inputBuffer.getChannelData(0);
        const isSilent = this.detectSilence(inputData);

        if (!isSilent) {
            this.handleSpeech(inputData, event.inputBuffer.duration);
        } else {
            this.handleSilence();
        }
    }

    private handleSpeech(inputData: Float32Array, bufferDuration: number) {
        this.silenceDuration = 0;
        if (!this.isTalking) {
            this.audioBuffer = [];
            this.recordedTime = 0;
        }
        this.audioBuffer.push(...inputData);
        this.recordedTime += bufferDuration * 1500; //this should be changed to 1500
        this.isTalking = true;
        isUserTalking.set(true)

        if (this.audioBuffer.length >= this.audioContext.sampleRate) {
            const oneSecondAudio = this.audioBuffer.slice(0, this.audioContext.sampleRate);
            const wavBuffer = this.convertFloat32ArrayToWAV(new Float32Array(oneSecondAudio));
            this.webSocket.send(wavBuffer);
            this.audioBuffer = this.audioBuffer.slice(this.audioContext.sampleRate);
        }
    }

    private handleSilence() {
        this.silenceDuration += this.audioProcessor.bufferSize / this.audioContext.sampleRate * 1000;
        if (this.silenceDuration >= this.silenceTimeout && this.isTalking) {
            const audioDuration = (this.audioBuffer.length / this.audioContext.sampleRate);

            if (this.audioBuffer.length > 0 && audioDuration > 0.3) {
                const remainingAudio = new Float32Array(this.audioBuffer);
                const wavBuffer = this.convertFloat32ArrayToWAV(remainingAudio);
                this.webSocket.send(wavBuffer);
            }

            this.webSocket.send('stopped_talking');
            this.isWaitingForResponse = true
            this.isTalking = false;
            isUserTalking.set(false)
            this.audioBuffer = [];
            this.silenceDuration = 0;
            this.stopStreaming();
        }
    }

    private detectSilence(data: Float32Array): boolean {
        let sum = 0;
        for (let i = 0; i < data.length; i++) {
            sum += data[i] * data[i];
        }
        let rms = Math.sqrt(sum / data.length);
        return rms < this.silenceThreshold;
    }

    private convertFloat32ArrayToWAV(inputData: Float32Array): ArrayBuffer {
        const bufferLength = inputData.length;
        const buffer = new ArrayBuffer(44 + bufferLength * 2);
        const view = new DataView(buffer);

        const pcmSamples = new Int16Array(bufferLength);
        this.floatTo16BitPCM(inputData, pcmSamples);

        this.writeWAVHeader(view, bufferLength);
        const pcmData = new Int16Array(buffer, 44);
        pcmData.set(pcmSamples);

        return buffer;
    }

    private floatTo16BitPCM(input: Float32Array, output: Int16Array) {
        for (let i = 0; i < input.length; i++) {
            const s = Math.max(-1, Math.min(1, input[i]));
            output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
        }
    }

    private writeWAVHeader(view: DataView, length: number) {
        // RIFF chunk descriptor
        this.writeString(view, 0, 'RIFF');
        view.setUint32(4, 36 + length * 2, true);
        this.writeString(view, 8, 'WAVE');
        // FMT sub-chunk
        this.writeString(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        view.setUint16(22, 1, true);
        view.setUint32(24, this.audioContext.sampleRate, true);
        view.setUint32(28, this.audioContext.sampleRate * 2, true);
        view.setUint16(32, 2, true);
        view.setUint16(34, 16, true);
        // Data sub-chunk
        this.writeString(view, 36, 'data');
        view.setUint32(40, length * 2, true);
    }

    private writeString(view: DataView, offset: number, string: string) {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    private async startStreaming() {

        this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.audioContext = new AudioContext();
        const source = this.audioContext.createMediaStreamSource(this.mediaStream);



        this.audioProcessor = this.audioContext.createScriptProcessor(4096, 1, 1);
        source.connect(this.audioProcessor);
        this.audioProcessor.connect(this.audioContext.destination);
    }

    private stopStreaming() {
        if (this.audioProcessor && this.audioContext) {
            this.audioProcessor.disconnect();
            this.audioContext.close();
        }
        if (this.mediaStream) {
            let tracks = this.mediaStream.getTracks();
            tracks.forEach(track => track.stop());
        }
    }

    destroy() {
        this.stopStreaming();
        this.webSocket.close();
    }
}
