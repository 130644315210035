import {getInterview} from "./interviewService";
import {
    currentContent,
    currentPage,
    errorMessage,
    errorWhileLookingForCode,
    interviewData,
    isLoading
} from "../redux/stores";
import Join from "../lib/Join.svelte";
import Landing from "../lib/Landing.svelte";
import PreInterviewContent from "../lib/PreInterviewContent.svelte";

export async function onClickJoin(code: string) {
    try {
        isLoading.set(true)
        const i = await getInterview(code)

        history.pushState(null, null, `/` + code);
        interviewData.set(i);
        localStorage.setItem("interviewId", i.id)
        localStorage.setItem("name", i.applicant.name)
        currentContent.set(Join);
        currentPage.set(PreInterviewContent)

    } catch (e) {
        const err = (e as string).toString()
        if (err.includes("2000")) {
            errorWhileLookingForCode.set(true);
            currentContent.set(Landing);
            currentPage.set(PreInterviewContent)
            errorMessage.set("Invalid meeting code")
        }
        if (err.includes("2002")) {
            errorWhileLookingForCode.set(true);
            currentContent.set(Landing);
            currentPage.set(PreInterviewContent)
            errorMessage.set("Thank you for being prompt! We appreciate your enthusiasm. Please note that our interview portal opens 15 minutes prior to your scheduled time. We encourage you to rejoin us closer to your appointment. Looking forward to speaking with you soon!")
        }
        if (err.includes("2003")) {
            errorWhileLookingForCode.set(true);
            currentContent.set(Landing);
            currentPage.set(PreInterviewContent)
            errorMessage.set("We noticed that your scheduled interview time has passed. We understand that unforeseen circumstances can arise. If you are still interested in the interview, please contact us to reschedule at your earliest convenience. We are eager to have the opportunity to speak with you.")
        }
        if (err.includes("2004")) window.location.replace(import.meta.env.VITE_SCHEDULE_INTERVIEW + code)
    }finally {
        isLoading.set(false)
    }
    throw Error()
}
