import {interviewData, isAudioPlaying} from "../../redux/stores";
import {AdjustableTimer} from "../../service/AdjustableTimer";
import {Chronometer} from "../../service/Chronometer";
import type {AudioServer} from "../../service/AudioServer";
import {get} from "svelte/store";

export class Playback {
    private mediaSource: MediaSource;
    private sourceBuffer: SourceBuffer;
    private audioElement: HTMLAudioElement;
    private queue: ArrayBuffer[] = [];
    private isFirst = true
    private timer: AdjustableTimer
    private binaryAudioQueue: Uint8Array[] = [];
    private firstTime?: number;

    constructor() {
        this.initializeMediaPlayback();
        isAudioPlaying.subscribe(i => {this.isFirst = !i})
    }

    private initializeMediaPlayback() {
        if (!window.MediaSource) {
            Error("Your browser doesn't support MediaSource API.");
            return;
        }

        this.audioElement = document.createElement('audio');
        this.audioElement.autoplay = true;
        document.body.appendChild(this.audioElement);

        this.mediaSource = new MediaSource();
        this.audioElement.src = URL.createObjectURL(this.mediaSource);
        this.mediaSource.addEventListener('sourceopen', this.sourceOpen.bind(this), false);
    }

    private sourceOpen() {
        this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/mpeg');
        this.sourceBuffer.addEventListener('updateend', this.updateEnd.bind(this), false);
    }

    private updateEnd() {
        if (this.queue.length > 0 && !this.sourceBuffer.updating) {
            this.sourceBuffer.appendBuffer(this.queue.shift()!);
        }
    }

    public playBase64Audio(base64: string) {
        if (!this.firstTime){
            this.firstTime = Chronometer.getInstance().getElapsedTime();
        }
        if (this.isFirst){
            isAudioPlaying.set(true)
            this.timer = new AdjustableTimer(this.calculateMP3Duration(base64)); // Start with 10 seconds
            this.timer.start();
            this.isFirst = false
        }else{
            this.timer.addTime(this.calculateMP3Duration(base64) );
        }
        // Convert base64 to binary and collect the binary audio chunks
        const audioChunk = this.base64ToUint8Array(base64);
        this.binaryAudioQueue.push(audioChunk);

        // Play audio chunk immediately
        if (this.sourceBuffer.updating || this.queue.length > 0) {
            this.queue.push(audioChunk.buffer);
        } else {
            this.sourceBuffer.appendBuffer(audioChunk.buffer);
        }
    }

    // Helper function to convert base64 to Uint8Array
    private base64ToUint8Array(base64: string): Uint8Array {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    private calculateMP3Duration(base64String: string): number {
        const binaryString = atob(base64String);
        const fileSizeInBits = binaryString.length * 8;
        const bitrate = 128 * 1024;
        return fileSizeInBits / bitrate;
    }

    // This function will be called when the final chunk is received
    public process() {
        const combinedArrayBuffer = this.combineBinaryAudio(this.binaryAudioQueue);
        this.downloadMP3(combinedArrayBuffer); // Pass the combined ArrayBuffer to download
    }

    // Helper function to concatenate binary (Uint8Array) audio chunks
    private combineBinaryAudio(binaryChunks: Uint8Array[]): ArrayBuffer {
        const totalLength = binaryChunks.reduce((acc, chunk) => acc + chunk.length, 0);

        const combinedArray = new Uint8Array(totalLength);
        let offset = 0;
        binaryChunks.forEach(chunk => {
            combinedArray.set(chunk, offset);
            offset += chunk.length;
        });

        return combinedArray.buffer;
    }

    // Trigger the MP3 download using the combined ArrayBuffer
    private async downloadMP3(audioBuffer: ArrayBuffer) {
        const blob = new Blob([audioBuffer], {type: 'audio/mpeg'});

        const base64 = (await this.blobToBase64(blob)).replace("data:audio/mpeg;base64,", "")

        const audio: AudioServer = {
            audio: base64, id: get(interviewData).id, time: this.firstTime!!
        }
        const response = await fetch(import.meta.env.VITE_STREAM_AUDIO_RECORDING, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(audio)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.text();
        console.log("Server response:", result);

        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'combined_audio.mp3';
        document.body.appendChild(a);
        //a.click();

        // Clean up after the download
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Event listener for when the FileReader has finished reading the Blob
            reader.onloadend = () => {
                const base64data = reader.result as string;
                resolve(base64data);
            };

            // Event listener for errors
            reader.onerror = reject;

            // Start reading the Blob as a data URL (Base64)
            reader.readAsDataURL(blob);
        });
    }

    public stopAudio() {
        if (this.audioElement && !this.audioElement.paused) {
            this.audioElement.pause(); // Pause the audio
            this.audioElement.currentTime = 0; // Reset the playback position
            isAudioPlaying.set(false); // Update the state to indicate audio is not playing
            if (this.timer) {
                this.timer.stop(); // Stop the timer if it's running
            }
        }
    }

}
